import logo from './logo.svg';
import './App.css';
import Background from './DSC_0128.jpg'

function App() {
  const difference = new Date("04/08/2006") - new Date()
  const differenceInDays = Math.round(Math.abs((difference) / (24 * 60 * 60 * 1000)))

  return (
    <div className="App" style={{  backgroundImage: `url(${Background})`}}>
      <div className="container">
        <div className="box">
          <h1>It's been</h1>
          <h1 className="days">
            {differenceInDays}
          </h1>
          <h1>days since the last ride <i>Buzz Lightyear Laser Blast</i> opened at Disneyland Park </h1>
        </div>
       </div>
    </div>
  );
}

export default App;
